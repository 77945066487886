import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import { getAccountInfo } from "../api/rest-apis";

interface HeaderProps {}

interface HeaderState {
  isMobileMenuOpen: boolean;
  toggleAccount: boolean;
  firstName: string;
  lastName: string;
  email: string;
}

class Header extends React.Component<HeaderProps, HeaderState> {
  state = {
    isMobileMenuOpen: false,
    toggleAccount: false,
    firstName: "",
    lastName: "",
    email: "",
  };

  constructor(props: HeaderProps) {
    super(props);
    this.logout = this.logout.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount(): void {
    this.getAccountInfo();
  }

  async getAccountInfo() {
    try {
      const response: any = await getAccountInfo();
      this.setState({
        firstName:
          response.firstName.charAt(0).toUpperCase() +
          response.firstName.slice(1),
        lastName: response.lastName
          ? response.lastName.charAt(0).toUpperCase() +
            response.lastName.slice(1)
          : "",
        email: response.email,
      });
    } catch (error) {
      console.error(error);
    }
  }

  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("user_id");
    localStorage.removeItem("email");
    document.cookie = `logged_in=; path=/; domain=bookishboyfriend.com; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; SameSite=None`;
    window.location.href = `${process.env.REACT_APP_REST_API_URI}/logout`;
  }

  login() {
    window.location.href = `${process.env.REACT_APP_REST_API_URI}/login`;
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({
      isMobileMenuOpen: !prevState.isMobileMenuOpen,
    }));
  };

  mobileLogout() {
    if (localStorage.getItem("token")) {
      localStorage.clear();
      return (window.location.href = `${process.env.REACT_APP_REST_API_URI}/logout`);
    }

    return (window.location.href = `${process.env.REACT_APP_REST_API_URI}/login`);
  }

  render() {
    const { isMobileMenuOpen } = this.state;

    return (
      <div className="page-header">
        <div className="page-header">
          <Link to="/">
            <div className="logo">
              <img src="/assets/images/logo.png" alt="" />
            </div>
          </Link>

          <Link to="/" className="flex">
            <h1>Bookish Boyfriend</h1>
            <span className="beta">Beta</span>
          </Link>
        </div>

        <div className="hidden lg:inline-block">
          <a
            className="flex items-center justify-center px-4 py-2 gap-2 bg-[var(--primary-color)] text-white font-medium text-sm rounded-lg shadow-lg hover:bg-opacity-90 focus:ring-2 focus:ring-[var(--primary-color)] focus:ring-offset-2 transition duration-300"
            href="https://community.bookishboyfriend.com/"
          >
            <img
              className="h-5 w-5"
              src="assets/images/community-white.svg"
              alt="Community Icon"
            />
            <p className="font-bold text-white">Community</p>
          </a>
        </div>

        {localStorage.getItem("token") && (
          <div className="hidden lg:inline-block relative  text-left ml-4 mr-4">
            <div>
              <button
                type="button"
                className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() =>
                  this.setState({ toggleAccount: !this.state.toggleAccount })
                }
              >
                Account
                <svg
                  className="-mr-1 h-5 w-5 text-gray-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {this.state.toggleAccount && (
              <div
                className=" absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabIndex={-1}
              >
                <div className="py-1" role="none">
                  <Link
                    to="/support"
                    className="text-gray-700 block px-4 py-2 text-sm"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-1"
                  >
                    Support
                  </Link>

                  <button
                    type="submit"
                    className="text-gray-700 block w-full px-4 py-2 text-left text-sm"
                    role="menuitem"
                    tabIndex={-1}
                    id="menu-item-3"
                    onClick={this.logout}
                  >
                    Sign out
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {!localStorage.getItem("token") && (
          <button
            className="ml-4 hidden lg:flex items-center justify-center px-4 py-2 gap-2 bg-[var(--primary-color)] !text-white font-medium text-sm rounded-lg shadow-lg hover:bg-opacity-90 focus:ring-2 focus:ring-[var(--primary-color)] focus:ring-offset-2 transition duration-300 mr-4"
            onClick={this.login}
          >
            <span className="font-bold !text-white">Login</span>
            <img
              className="h-5 w-5"
              src="/assets/images/account-white.svg"
              alt="Account Icon"
            />
          </button>
        )}

        <div className="mobile-navigation" onClick={this.toggleMobileMenu}>
          <img src="/assets/images/mobile-menu.svg" alt="" />
        </div>

        {/* Mobile version navigation system */}

        <div className={`${isMobileMenuOpen ? "mobile-menu" : "hidden"}`}>
          <div className="flex">
            <div className="page-header">
              <Link to="/">
                <div className="logo">
                  <img src="/assets/images/logo.png" alt="" />
                </div>
              </Link>

              <Link to="/">
                <h1>Bookish Boyfriend</h1>
              </Link>
            </div>

            <div className="mobile-navigation" onClick={this.toggleMobileMenu}>
              <img src="/assets/images/close-menu.svg" alt="" />
            </div>
          </div>

          <div
            className="flex flex-col justify-start gap-1 ml-4 mobile-menu-list"
            style={{ marginTop: ".5rem" }}
          >
            <NavLink to="/">
              <div className="flex justify-start cursor-pointer p-4 w-[95%] mobile-navlink">
                <img src="/assets/images/home-white.svg" alt="" />
                <p className="align-center ml-8">Home</p>
              </div>
            </NavLink>

            <NavLink to="/chats">
              <div className="flex justify-start cursor-pointer p-4 w-[95%] mobile-navlink">
                <img src="/assets/images/chats-white.svg" alt="" />
                <p className="align-center ml-8">Chats</p>
              </div>
            </NavLink>

            <NavLink to="/saved">
              <div className="flex justify-start cursor-pointer p-4 w-[95%] mobile-navlink">
                <img src="/assets/images/saved-white.svg" alt="" />
                <p className="align-center ml-8">Saved</p>
              </div>
            </NavLink>

            <NavLink to="/price">
              <div className="flex justify-start cursor-pointer p-4 w-[95%] mobile-navlink">
                <img src="/assets/images/bill-white.svg" alt="" />
                <p className="align-center ml-8">Pricing</p>
              </div>
            </NavLink>

            <a
              className="flex justify-start cursor-pointer p-4 w-[95%] mobile-navlink"
              href="https://community.bookishboyfriend.com/"
            >
              <img src="/assets/images/community.svg" alt="" />
              <p className="align-center ml-8">Community</p>
            </a>

            <NavLink to="/support">
              <div className="flex justify-start cursor-pointer p-4 w-[95%] mobile-navlink">
                <img src="/assets/images/support-white.svg" alt="" />
                <p className="align-center ml-8">Support</p>
              </div>
            </NavLink>

            <NavLink to="/setting">
              <div className="flex justify-start cursor-pointer p-4 w-[95%] mobile-navlink mb-4">
                <img src="/assets/images/setting-white.svg" alt="" />
                <p className="align-center ml-8">Settings</p>
              </div>
            </NavLink>

            <div className="absolute z-10 bg-[#F1F2ED] bottom-1 mb-2 user-info-bottom w-[90%]">
              {localStorage.getItem("token") && (
                <div className="flex justify-start cursor-pointer">
                  <div className="user-first-letter-icon">
                    {this.state.firstName.length > 0
                      ? this.state.firstName.charAt(0)
                      : ""}
                  </div>
                  <div className="align-center ml-4">
                    <p> {this.state.firstName + " " + this.state.lastName}</p>
                    <p className="email-address">{this.state.email}</p>
                  </div>
                </div>
              )}

              <div
                className="flex justify-start items-end cursor-pointer"
                onClick={() => this.mobileLogout()}
              >
                {localStorage.getItem("token") ? (
                  <div
                    className="logout flex justify-center items-center p-1 mr-3 cursor-pointer mt-5"
                    style={{
                      border: "2px solid #D14444",
                      color: "#D14444 !important",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    <img
                      className="h-[2rem]"
                      src="/assets/images/logout.svg"
                      alt=""
                    />
                    <p style={{ color: "#D14444", fontWeight: 600 }}>Logout</p>
                  </div>
                ) : (
                  <div
                    className="logout flex justify-center items-center p-1 mr-3 cursor-pointer mt-5"
                    style={{
                      border: "2px solid #36434D",
                      color: "#36434D !important",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    <img
                      className="h-[2rem]"
                      src="/assets/images/account-whitesmoke.svg"
                      alt=""
                    />
                    <p style={{ color: "#36434D", fontWeight: 600 }}>Login</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
