import React from "react";
import { Link } from "react-router-dom";
import {
  deleteCharacter,
  getCharacters,
  updateCharacterOrder,
} from "../../../api/rest-apis";
import { toastHelper } from "../../../helpers/toast";
import { Character } from "../../../interfaces/rest-api";
import DeleteModal from "./delete-modal";
import DragOrderCharacters from "../helpers/drag-order-characters";

interface ListCharacterProps {}

interface ListCharacterState {
  characters: Character[];
  deleteCharacterId: string;
  showDeleteModal: boolean;
}

class ListCharacter extends React.Component<
  ListCharacterProps,
  ListCharacterState
> {
  state = { characters: [], showDeleteModal: false, deleteCharacterId: "" };

  constructor(props: ListCharacterProps) {
    super(props);
    this.getCharacters();
    this.getCharacters = this.getCharacters.bind(this);
    this.deleteCharacter = this.deleteCharacter.bind(this);
    this.handleReorder = this.handleReorder.bind(this); // Bind the reorder function
  }

  async getCharacters() {
    try {
      const response: any = await getCharacters();
      this.setState({ characters: response.characters });
    } catch (error) {
      console.error(error);
    }
  }

  async deleteCharacter() {
    const id = toastHelper.loading("Deleting ...");
    try {
      await deleteCharacter(this.state.deleteCharacterId);
      toastHelper.success("Character has been deleted!", id);
      this.setState({ showDeleteModal: false, deleteCharacterId: "" });
      setTimeout(async () => await this.getCharacters(), 500);
    } catch (error) {
      toastHelper.error("Error deleting character", id);
      this.setState({ showDeleteModal: false, deleteCharacterId: "" });
    }
  }

  handleReorder = async (updatedList: Character[]) => {
    // Update the state with the reordered list
    this.setState({ characters: updatedList });

    // Prepare the list with updated positions
    const charactersWithPositions: any = updatedList.map(
      (character, index) => ({
        _id: character._id,
        position: index,
      })
    );

    try {
      // Call the Axios-wrapped function to update character order on the backend
      await updateCharacterOrder(charactersWithPositions);
      console.log("Character order updated successfully!");
    } catch (error) {
      console.error("Error updating character order:", error);
    }
  };

  render() {
    return (
      <>
        <div className="edit-character">
          <div className="title">Characters</div>
          <div className="bookish-input-group">
            <Link to="/admin/create-character">
              <button
                className="bookish-secondary-btn"
                style={{ textAlign: "start" }}
              >
                Create Character
              </button>
            </Link>
          </div>

          <DragOrderCharacters
            list={this.state.characters}
            onReorder={this.handleReorder}
          >
            {(character, index) => (
              <div className="character-card" key={character._id}>
                <img
                  className="character-image"
                  src={character.character_profile}
                  alt=""
                />
                <p
                  className="edit-character-desc"
                  dangerouslySetInnerHTML={{
                    __html: character.human_description,
                  }}
                ></p>
                <p className="edit-character-title">
                  {character.character_name}
                </p>
                <div className="flex justify-center gap-5">
                  <Link to={`/admin/edit-character/${character._id}`}>
                    <img
                      className="edit-icon"
                      src="assets/images/edit.svg"
                      alt=""
                    />
                  </Link>

                  <Link to={`/admin/character-analytics/${character._id}`}>
                    <img
                      className="edit-icon"
                      src="assets/images/analytics.svg"
                      alt=""
                    />
                  </Link>

                  <button
                    onClick={() =>
                      this.setState({
                        showDeleteModal: true,
                        deleteCharacterId: character._id,
                      })
                    }
                  >
                    <img
                      className="edit-icon"
                      src="assets/images/delete.svg"
                      alt=""
                    />
                  </button>
                </div>
              </div>
            )}
          </DragOrderCharacters>
        </div>

        {this.state.showDeleteModal && (
          <>
            {this.state.characters
              .filter(
                (value: any) => value._id === this.state.deleteCharacterId
              )
              .map((character: any) => (
                <DeleteModal
                  key={character._id}
                  character_name={
                    character.character_name || "Unknown Character"
                  }
                  close_modal={() =>
                    this.setState({
                      showDeleteModal: false,
                      deleteCharacterId: "",
                    })
                  }
                  delete_it={() => this.deleteCharacter()}
                />
              ))}
          </>
        )}
      </>
    );
  }
}

export default ListCharacter;
