import React, { useState } from "react";

interface DragOrderCharactersProps<T> {
  list: T[];
  onReorder: (updatedList: T[]) => void;
  children: (item: T, index: number) => React.ReactNode;
}

const DragOrderCharacters: React.FC<DragOrderCharactersProps<any>> = ({
  list,
  onReorder,
  children,
}) => {
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.preventDefault();
    if (draggingIndex === null || draggingIndex === index) return;

    const updatedList = [...list];
    const [draggedItem] = updatedList.splice(draggingIndex, 1);
    updatedList.splice(index, 0, draggedItem);

    onReorder(updatedList);
    setDraggingIndex(index); // Update dragging index to new position
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
  };

  return (
    <div className="flex overflow-x-auto">
      <div className="inline-flex space-x-4">
        {list.map((item, index) => (
          <div
            key={index}
            draggable
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => handleDragOver(e, index)}
            onDragEnd={handleDragEnd}
            style={{
              cursor: "grab",
            }}
          >
            {children(item, index)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DragOrderCharacters;
