import React, { useState } from "react";

interface DragDropProps<T> {
  list: T[];
  onReorder: (updatedList: T[]) => void;
  children: (item: T, index: number) => React.ReactNode;
}

const DragDrop: React.FC<DragDropProps<any>> = ({
  list,
  onReorder,
  children,
}) => {
  const [draggingIndex, setDraggingIndex] = useState<number | null>(null);

  const handleDragStart = (index: number) => {
    setDraggingIndex(index);
  };

  const handleDragOver = (
    event: React.DragEvent<HTMLDivElement>,
    index: number
  ) => {
    event.preventDefault();
    if (draggingIndex === null || draggingIndex === index) return;

    const updatedList = [...list];
    const [draggedItem] = updatedList.splice(draggingIndex, 1);
    updatedList.splice(index, 0, draggedItem);

    onReorder(updatedList);
    setDraggingIndex(index); // Update dragging index to new position
  };

  const handleDragEnd = () => {
    setDraggingIndex(null);
  };

  return (
    <div
      className="drag-drop-container grid grid-cols-2 gap-4"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)", // Ensures the grid has two columns
        gap: "1rem", // Adjust the gap between items
      }}
    >
      {list.map((item, index) => (
        <div
          key={index}
          draggable
          onDragStart={() => handleDragStart(index)}
          onDragOver={(e) => handleDragOver(e, index)}
          onDragEnd={handleDragEnd}
          style={{
            cursor: "grab",
          }}
        >
          {children(item, index)}
        </div>
      ))}
    </div>
  );
};

export default DragDrop;
