import React, { Component } from "react";
import { Link } from "react-router-dom"; // Ensure react-router-dom is installed
import { convertOldNamesToNewPlan, planNameToInteger } from "../helpers/util";

interface PromptTabMobileProps {
  sample_prompts: { sample_prompt: string; plan: string }[];
  book_talk_prompts: string[];
  userPlan: string;
  bookTalk: string | null;
  updateParams: (params: {
    prompt: string;
    bookTalk: boolean;
    action: string;
  }) => void;
}

interface PromptTabMobileState {
  activeTab: "personalityMode" | "bookTalk" | any;
  isModalOpen: boolean;
  requiredTier: string;
}

interface tiersInNumbers {
  subscriber: number;
  patron: number;
  premium: number;
}

const tiersInNumbers: tiersInNumbers = {
  subscriber: 1,
  patron: 2,
  premium: 3,
};

const tier: keyof typeof tiersInNumbers = "subscriber";

class PromptTabMobile extends Component<
  PromptTabMobileProps,
  PromptTabMobileState
> {
  constructor(props: PromptTabMobileProps) {
    super(props);
    this.state = {
      activeTab: localStorage.getItem("chat-active-tab") || "personalityMode",
      isModalOpen: false,
      requiredTier: "",
    };
  }

  setActiveTab = (tab: "personalityMode" | "bookTalk") => {
    this.setState({ activeTab: tab });
    localStorage.setItem("chat-active-tab", tab);
  };

  openModal = (tier: string) => {
    this.setState({ isModalOpen: true, requiredTier: tier });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, requiredTier: "" });
  };

  renderModal() {
    const { isModalOpen } = this.state;

    if (!isModalOpen) return null;

    return (
      <div
        className="fixed inset-0 px-2 z-10 overflow-hidden flex items-center justify-center transition-opacity duration-300"
        onClick={this.closeModal}
      >
        <div className="absolute inset-0 bg-gray-500 bg-opacity-75"></div>
        <div
          className="bg-white rounded-md shadow-xl overflow-hidden max-w-md w-full sm:w-96 md:w-1/2 lg:w-2/3 xl:w-1/3 z-50 transition-all duration-300 scale-100"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Modal Header */}
          <div className="text-white px-4 py-2 flex justify-between items-center bg-primary">
            <h2 className="text-lg font-semibold text-white">
              Unlock{" "}
              {this.state.requiredTier?.charAt(0).toUpperCase() +
                this.state.requiredTier?.slice(1)}{" "}
              Features
            </h2>
          </div>
          {/* Modal Body */}
          <div className="p-4 text-center">
            {this.state.requiredTier === "patron" ? (
              <p className="text-gray-800 text-lg mb-3">
                Get access to exclusive short stories, works-in-progress,
                additional ChatPoints and more by upgrading to the Patron Plan.
              </p>
            ) : (
              <p className="text-gray-800 text-lg mb-3">
                Get access to additional characters, long conversations,
                full-length novels and more by upgrading to the Premium Plan.
              </p>
            )}

            <Link to="/price" className="text-primary underline mt-4">
              Click here to upgrade now and enjoy exclusive benefits!
            </Link>
          </div>
          {/* Modal Footer */}
          <div className="flex justify-end border-t p-4">
            <div className="mt-2">
              {" "}
              <button
                onClick={() => this.setState({ isModalOpen: false })}
                className="px-4 py-2 mr-2 bg-gray-300 hover:bg-gray-400 text-gray-800 rounded-md"
              >
                Close
              </button>
            </div>

            <Link to="/price" className="text-primary underline mt-2">
              <button className="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-md font-semibold">
                Upgrade Now
              </button>
            </Link>
          </div>
          {/* Close Button */}

          <button
            onClick={this.closeModal}
            className="absolute -top-0 -right-0 bg-primary font-semibold hover:opacity-100 text-2xl w-10 h-10 rounded-full focus:outline-none text-white z-10"
          >
            ✗
          </button>
        </div>
      </div>
    );
  }

  renderContent() {
    const { activeTab } = this.state;
    const {
      sample_prompts,
      book_talk_prompts,
      userPlan,
      bookTalk,
      updateParams,
    } = this.props;

    if (activeTab === "personalityMode") {
      return (
        <div key={146}>
          {/* Set max height and enable vertical scrolling */}
          <div className="flex-grow max-h-[80vh] overflow-y-auto">
            {sample_prompts.map(
              (
                prompt: { sample_prompt: string; plan: string },
                index: number
              ) => (
                <div
                  key={prompt.sample_prompt}
                  className="sample-prompt flex justify-between items-center align-center border-b my-4 pb-2 cursor-pointer"
                  onClick={() => {
                    const userPlan =
                      this.props.userPlan === "Basic"
                        ? "subscriber"
                        : this.props.userPlan === "Plus"
                        ? "patron"
                        : "premium";

                    if (
                      tiersInNumbers[prompt.plan as keyof tiersInNumbers] >
                      tiersInNumbers[userPlan as keyof tiersInNumbers]
                    ) {
                      this.openModal(prompt.plan);
                      return;
                    }
                    if (bookTalk === "true") {
                      updateParams({
                        prompt: prompt.sample_prompt,
                        bookTalk: false,
                        action: "null",
                      });
                      setTimeout(() => window.location.reload(), 100);
                    } else {
                      updateParams({
                        prompt: prompt.sample_prompt,
                        bookTalk: false,
                        action: "null",
                      });
                    }
                  }}
                >
                  <span
                    className="actual-prompt"
                    style={{
                      width: prompt.plan !== "subscriber" ? "80%" : "100%",
                    }}
                  >
                    {prompt.sample_prompt}
                  </span>

                  {prompt.plan !== "subscriber" &&
                    planNameToInteger(prompt.plan) >
                      planNameToInteger(
                        convertOldNamesToNewPlan(userPlan.toLowerCase())
                      ) && (
                      <span
                        className={
                          prompt.plan === "premium"
                            ? "tier-premium"
                            : prompt.plan === "patron"
                            ? "tier-patron"
                            : ""
                        }
                      >
                        {prompt.plan.charAt(0).toLocaleUpperCase() +
                          prompt.plan.slice(1)}
                      </span>
                    )}
                </div>
              )
            )}
          </div>
        </div>
      );
    } else if (activeTab === "bookTalk") {
      return (
        <div key={145}>
          {/* Set max height and enable vertical scrolling */}
          <div className="flex-grow max-h-[50vh] overflow-y-auto suggestion-prompts pb-[4rem]">
            {book_talk_prompts.map((prompt: any, index: number) => (
              <div
                key={prompt}
                className="sample-prompt flex justify-between items-center align-center border-b my-4 pb-2 cursor-pointer"
                onClick={async () => {
                  const updatedUserPlan =
                    this.props.userPlan === "Basic"
                      ? "subscriber"
                      : this.props.userPlan === "Plus"
                      ? "patron"
                      : "premium";

                  if (
                    tiersInNumbers[prompt.plan as keyof tiersInNumbers] >
                    tiersInNumbers[updatedUserPlan as keyof tiersInNumbers]
                  ) {
                    this.openModal(prompt.plan);
                    return;
                  }

                  if (bookTalk === "false" || bookTalk === null) {
                    updateParams({
                      prompt: prompt.sample_prompt,
                      bookTalk: true,
                      action: "null",
                    });
                    setTimeout(() => window.location.reload(), 100);
                  } else {
                    updateParams({
                      prompt: prompt.sample_prompt,
                      bookTalk: true,
                      action: "null",
                    });
                  }
                }}
              >
                <span
                  className="actual-prompt"
                  style={{
                    width: prompt.plan !== "subscriber" ? "80%" : "100%",
                  }}
                >
                  {prompt.sample_prompt}
                </span>

                {prompt.plan !== "subscriber" &&
                  planNameToInteger(prompt.plan) >
                    planNameToInteger(
                      convertOldNamesToNewPlan(userPlan.toLowerCase())
                    ) && (
                    <span
                      className={
                        prompt.plan === "premium"
                          ? "tier-premium"
                          : prompt.plan === "patron"
                          ? "tier-patron"
                          : ""
                      }
                    >
                      {prompt.plan.charAt(0).toLocaleUpperCase() +
                        prompt.plan.slice(1)}
                    </span>
                  )}
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  render() {
    const { activeTab } = this.state;

    return (
      <div>
        <div className="relative w-full h-full bg-gray-200 rounded-full p-1">
          {/* Sliding indicator */}
          <div
            className={`absolute top-0 left-0 h-full w-1/2 bg-[--primary-color] rounded-full transform transition-transform duration-300 ${
              activeTab === "personalityMode"
                ? "translate-x-0"
                : "translate-x-full"
            }`}
          ></div>

          {/* Tabs */}
          <div className="relative flex justify-between">
            <button
              className={`flex-1 text-center text-sm py-2 rounded-full z-10 transition-colors  font-semibold ${
                activeTab === "personalityMode"
                  ? "text-[#fff]"
                  : "text-[#444444]"
              }`}
              onClick={() => this.setActiveTab("personalityMode")}
            >
              Personality Mode
            </button>
            <button
              className={`flex-1 text-center text-sm py-2 rounded-full z-10 transition-colors font-semibold  ${
                activeTab !== "personalityMode"
                  ? "text-[#fff]"
                  : "text-[#444444]"
              }`}
              onClick={() => this.setActiveTab("bookTalk")}
            >
              Book Talk
            </button>
          </div>
        </div>

        {this.renderContent()}
        {this.renderModal()}
      </div>
    );
  }
}

export default PromptTabMobile;
