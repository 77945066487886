import * as React from "react";
import { Link, NavLink } from "react-router-dom";

interface SideMenuProps {}

interface SideMenuState {}

class SideMenu extends React.Component<SideMenuProps, SideMenuState> {
  state = {};
  render() {
    return (
      <>
        <div className="flex-none flex flex-col side-nav">
          <NavLink
            className={({ isActive }) =>
              isActive
                ? "flex flex-col nav-btn justify-center nav-active"
                : "flex flex-col nav-btn justify-center"
            }
            to="/"
          >
            <img src="assets/images/home.svg" alt="" />
            <p>Home</p>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive
                ? "flex flex-col nav-btn justify-center nav-active"
                : "flex flex-col nav-btn justify-center"
            }
            to={"/chats"}
          >
            <img src="assets/images/chats.svg" alt="" />
            <p>Chats</p>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive
                ? "flex flex-col nav-btn justify-center nav-active"
                : "flex flex-col nav-btn justify-center"
            }
            to="/saved"
          >
            <img src="assets/images/heart.svg" alt="" />
            <p>Saved</p>
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive
                ? "flex flex-col nav-btn justify-center nav-active"
                : "flex flex-col nav-btn justify-center"
            }
            to="/price"
          >
            <img src="assets/images/bill.svg" alt="" />
            <p>Pricing</p>
          </NavLink>

          

          <NavLink
            className={({ isActive }) =>
              isActive
                ? "flex flex-col nav-btn justify-center nav-active"
                : "flex flex-col nav-btn justify-center"
            }
            to="/setting"
          >
            <img src="assets/images/setting.svg" alt="" />
            <p>Settings</p>
          </NavLink>
        </div>
      </>
    );
  }
}

export default SideMenu;
